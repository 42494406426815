<template>
  <div class="custom-popover">
    <div class="popover-content" :style="`left: ${left}px`">
      <slot/>
    </div>
  </div>
</template>

<script>
export default {
  name: "CustomPopOver",

  props: {
    left: Number
  }

}
</script>

<style lang="scss" scoped>
.custom-popover {
  position: relative;
  display: inline-block;

  .popover-content {
    padding: 5px 6px;
    margin-top: 5px;
    display: block;
    position: absolute;
    top: 10px;
    background-color: #1d1d1d;
    min-width: 200px;
    max-width: 250px;
    overflow: auto;
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    z-index: 2;
    //color: #212529;
    color: #eeeeee;
  }

}
</style>
